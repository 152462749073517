import React, { useState } from "react";
import InstitutionService from "services/InstitutionService";
import studentSevice from "services/studentService";
import taskService from "services/taskService";
import TeacherRoleService from "services/teacherRoleService";

type statData = {
    Date: string,
    Total: number,
    Change: number,
}

type statActivityData = {
    Id: string,
    Name?: string,
    Email?: string,
    Count?: number,
    DaysCount?: number
}

type statDifficultyData = {
    difficulty: string,
    correctCount: number,
    totalCount: number
}

function DifficultyTable(props: { data: statDifficultyData[] }) {
    if (props.data.length === 0) { return (<div style={{ textAlign: 'center' }}>Ingen data</div>) }
    else {
        return (
            <table>
                <thead>
                    <th style={{ padding: '5px' }}>Svårighet</th>
                    <th style={{ padding: '5px' }}>Svar</th>
                </thead>
                <tbody>
                    {props.data.map((row, index) =>
                        <tr>
                            <td style={{ padding: '5px' }}>{row.difficulty}-{+row.difficulty+99}</td>
                            <td style={{ padding: '5px' }}>{`${row.correctCount}/${row.totalCount}`}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }
}

function ActivityTable(props: { type: string, data: statActivityData[] }) {
    if (props.data.length === 0) { return (<div style={{ textAlign: 'center' }}>Ingen data</div>) }
    else {
        const showId = props.type !== 'Skolaktivitet';
        return (
            <table>
                <thead>
                    {showId && <th style={{ padding: '5px' }}>Id</th>}
                    {props.data.filter(d => d.Name).length > 0 && <th style={{ padding: '5px' }}>Namn</th>}
                    {props.data.filter(d => d.Email).length > 0 && <th style={{ padding: '5px' }}>Email</th>}
                    {props.data.filter(d => d.Count).length > 0 && <th style={{ padding: '5px' }}>Antal svar</th>}
                    {props.data.filter(d => d.DaysCount).length > 0 && <th style={{ padding: '5px' }}>Antal dagar</th>}
                </thead>
                <tbody>
                    {props.data.map((row, index) =>
                        <tr key={index}>
                            {showId && <td style={{ padding: '5px' }}>{row.Id}</td>}
                            {props.data.filter(d => d.Name).length > 0 && 
                                (row.Name ? <td style={{ padding: '5px' }}>{row.Name}</td> : <td style={{ padding: '5px' }}>-</td>)
                            }
                            {props.data.filter(d => d.Email).length > 0 && 
                                (row.Email ? <td style={{ padding: '5px' }}>{row.Email}</td> : <td style={{ padding: '5px' }}>-</td>)
                            }
                            {props.data.filter(d => d.Count).length > 0 && 
                                (row.Count ? <td style={{ padding: '5px' }}>{row.Count}</td> : <td style={{ padding: '5px' }}>-</td>)
                            }
                            {props.data.filter(d => d.DaysCount).length > 0 && 
                                (row.DaysCount ? <td style={{ padding: '5px' }}>{row.DaysCount}</td> : <td style={{ padding: '5px' }}>-</td>)
                            }
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

}

function StatisticTable(props: { type: string, data: statData[], activityData: statActivityData[], difficultyData: statDifficultyData[],  interval: number }) {
    if (props.type === 'Skolaktivitet' || props.type === 'Elev Aktivitet Dagar' || props.type ==='Elev Aktivitet Korrekt') {
        return ActivityTable({ type: props.type, data: props.activityData })
    }
    if(props.type === 'Svar per nivå') {
        return DifficultyTable({ data: props.difficultyData })
    }
    else if (props.data.length === 0) { return (<div style={{ textAlign: 'center' }}>Ingen data</div>) }
    else {
        let data: statData[] = []
        if (props.interval > -1) {
            const date = new Date()
            for (let i = 0; i <= props.interval; i++) {
                const currentDate = new Date(date)
                currentDate.setDate(currentDate.getDate() - i)
                const month = (currentDate.getMonth() + 1) < 10 ? `0${currentDate.getMonth() + 1}` : `${currentDate.getMonth() + 1}`;
                const day = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : `${currentDate.getDate()}`;
                const fromDb = props.data.find(d => currentDate.toISOString().includes(d.Date))
                if (!fromDb) {
                    const total = i > 0 ? data[i - 1].Total - data[i - 1].Change : props.data[0].Total
                    data.push({ Date: `${currentDate.getFullYear()}-${month}-${day}`, Total: total, Change: 0 })
                } else {
                    data.push(fromDb)
                }
            }
        } else {
            data = props.data
        }
        return (
            <table>
                <thead>
                    <th style={{ padding: '5px' }}>Datum</th>
                    <th style={{ padding: '5px' }}>Summa</th>
                    <th style={{ padding: '5px' }}>Förändring</th>
                </thead>
                <tbody>
                    {data.map((row, index) =>
                        <tr>
                            <td style={{ padding: '5px' }}>{row.Date}</td>
                            <td style={{ padding: '5px' }}>{row.Total}</td>
                            <td style={{ padding: '5px' }}>{row.Change}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }
}

function HandleStatistics() {
    const dates = [7, 30, -1];
    const [selectedInterval, setSelectedInterval] = useState<number>(7)
    const statistics = ['Elever', 'Lärare', 'Alla svar', 'Uppgifter', 'Felanmälda frågor', 'Skolor', 'Skolaktivitet', 'Elev Aktivitet Dagar', 'Elev Aktivitet Korrekt', 'Svar per nivå']
    const [expanded, setExpanded] = useState<string[]>([])
    const [data, setData] = useState<statData[]>([])
    const [activityData, setActivityData] = useState<statActivityData[]>([])
    const [difficultyData, setDifficultyData] = useState<statDifficultyData[]>([])
    const [isWorking, setIsWorking] = useState(false)
    const [notImplemented, setNotImplemented] = useState(false)

    const handleInterval = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newInterval = +e.target.value
        setSelectedInterval(newInterval)
        if (expanded.length > 0) {
            setIsWorking(true)
            getStatisticData(expanded[0], newInterval).finally(() => setIsWorking(false))
        }
    }

    const expand = (statistic: string) => {
        setData([])
        setActivityData([])
        setNotImplemented(false)
        setIsWorking(true)
        if (expanded.includes(statistic)) {
            setExpanded(e => e.splice(e.indexOf(statistic), 1))
        } else {
            getStatisticData(statistic, selectedInterval).finally(() => setIsWorking(false))
            setExpanded([statistic])
        }
    }

    const getStatisticData = async (statistic: string, interval: number) => {
        const date = new Date();
        date.setDate(date.getDate()+1);
        switch (statistic) {
            case 'Elever':
                await studentSevice.getStudentsCountByDate(date, interval).then(data => {
                    setData(data?.map((d: any, index: number) => {
                        return { Date: d.date.split('T')[0], Total: d.total, Change: d.change }
                    }) || [])
                });
                break;
            case 'Lärare':
                await TeacherRoleService.getTeachersCountByDate(date, interval).then(data => {
                    setData(data?.map((d: any, index: number) => ({ Date: d.date.split('T')[0], Total: d.total, Change: d.change })) || [])
                });
                break;
            case 'Alla svar':
                await taskService.getTaskActivitesCountByDate(date, interval).then(data => {
                    setData(data?.map((d: any, index: number) => ({ Date: d.date.split('T')[0], Total: d.total, Change: d.change })) || [])
                });
                break;
            case 'Uppgifter':
                await taskService.getTasksCountByDate(date, interval).then(data => {
                    setData(data?.map((d: any, index: number) => ({ Date: d.date.split('T')[0], Total: d.total, Change: d.change })) || [])
                });
                break;
            case 'Felanmälda frågor':
                await taskService.getFeedbackCountByDate(date, interval).then(data => {
                    setData(data?.map((d: any, index: number) => ({ Date: d.date.split('T')[0], Total: d.total, Change: d.change })) || [])
                });
                break;
            case 'Skolor':
                await InstitutionService.getInstitutionsCountByDate(date, interval).then(data => {
                    setData(data?.map((d: any, index: number) => ({ Date: d.date.split('T')[0], Total: d.total, Change: d.change })) || [])
                });
                break;
            case 'Skolaktivitet':
                await InstitutionService.getInstitutionActivityCount(interval).then(data => {
                    setData([])
                    setActivityData(data?.map((d: any, index: number) => ({ Id: d.id, Name: d.name, Count: d.count })) || [])
                });
                break;
            case 'Elev Aktivitet Dagar':
                await studentSevice.getStudentActivityCount(interval).then(data => {
                    setData([])
                    setActivityData(data?.map((d: any, index: number) => ({ Id: d.id, Email: d.email, DaysCount: d.count })) || [])
                });
                break;
            case 'Elev Aktivitet Korrekt':
                await studentSevice.getStudentCorrectActivityCount(interval).then(data => {
                    setData([])
                    setDifficultyData([])
                    setActivityData(data?.map((d: any, index: number) => ({ Id: d.id, Email: d.email, Count: d.count })) || [])
                });
                break;
            case 'Svar per nivå':
                await taskService.getDifficultyCountByDate(date, interval).then(data => {
                    setData([])
                    setActivityData([])
                    const dataArray = data as any[];
                    const difficultyData: statDifficultyData[] = []
                    for(let i = 0; i < 1000; i += 100) {
                        let result = { difficulty: i.toString(), correctCount: 0, totalCount: 0 }
                        dataArray.filter(d => d.difficulty >= i && d.difficulty < (i+100)).map(d => {
                            if(d.assessment === 'correct') {
                                result.correctCount += +d.count;
                            }
                            result.totalCount += +d.count;
                        });
                        difficultyData.push(result)
                    }
                    const thousandPlus = dataArray.filter(d => d.difficulty >= 1000).length > 0;
                    if(thousandPlus) {
                        let result = { difficulty: '1000+', correctCount: 0, totalCount: 0 }
                        dataArray.filter(d => d.difficulty >= 1000).map(d => {
                            if(d.assessment === 'correct') {
                                result.correctCount += +d.count;
                            }
                            result.totalCount += +d.count;
                        });
                        difficultyData.push(result)
                    }
                    setDifficultyData(difficultyData || [])
                });
                break;
            default:
                setNotImplemented(true)
                setData([])
                setActivityData([])
                setDifficultyData([])
                setIsWorking(false)
                break;
        }

    }

    return (
        <div style={{ textAlign: 'center' }}>
            <h1>Statistics</h1>
            <span><b>Uppföljning: </b></span>
            <select onChange={handleInterval}>
                {dates.map(date => <option key={date} value={Number.isNaN(date) ? 1 : date}>{date > -1 ? date : 'All time'}</option>)}
            </select>
            <div style={{ margin: 'auto', width: '50%' }}>
                {statistics.map(statistic => <div key={statistic} style={{ textAlign: 'left', marginTop: '5px' }}>
                    <b onClick={() => expand(statistic)}>{'> ' + statistic}:</b>
                    {expanded.includes(statistic) && <>
                        {notImplemented ?
                            <div style={{ textAlign: 'center' }}>Inte implementerad</div>
                            : <>{!isWorking ? <StatisticTable type={statistic} data={data} activityData={activityData} difficultyData={difficultyData} interval={selectedInterval}></StatisticTable>
                                : <div style={{ textAlign: 'center' }}>Hämtar data</div>}</>
                        }
                    </>}
                </div>)}
            </div>
        </div>
    )
}

export default HandleStatistics;